<template>
  <Survey :iframe-url="$route.params.url" :document-id="$route.params.id" />
</template>

<script>
import Survey from '@/components/Survey.vue';

export default {
  name: 'Preview',
  components: { Survey },
};
</script>
